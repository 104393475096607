@import url(animate.css);
/* @import url(../scss/icons/font-awesome/css/font-awesome.min.css);
@import url(../scss/icons/simple-line-icons/css/simple-line-icons.css);
@import url(../scss/icons/weather-icons/css/weather-icons.min.css); */
@import url(themify-icons.css);
@import url(iconmind.css);

@font-face {
  font-family: 'fa-regular';
  src:
    url('../font/fa-regular-400.woff2') format('woff2'),
    url('../font/fa-regular-400.woff') format('woff'),
    url('../font/fa-regular-400.ttf') format('truetype');
}
@font-face {
  font-family: 'fa-brands';
  src:
    url('../font/fa-brands-400.woff2') format('woff2'),
    url('../font/fa-brands-400.woff') format('woff'),
    url('../font/fa-brands-400.ttf') format('truetype');
}
@font-face {
  font-family: 'fa-solid';
  src:
    url('../font/fa-solid-900.woff2') format('woff2'),
    url('../font/fa-solid-900.woff') format('woff'),
    url('../font/fa-solid-900.ttf') format('truetype');
}
@font-face {
  font-family: 'icomoon';
  src:
    url('../font/icomoon.woff') format('woff'),
    url('../font/icomoon.ttf') format('truetype');
}
@font-face {
  font-family: 'themify';
  src:
    url('../font/themify.woff') format('woff'),
    url('../font/themify.ttf') format('truetype');
}

/*******************
Preloader
********************/
@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700,800');
.preloader {
  width: 100%;
  height: 100%;
  top: 0px;
  position: fixed;
  z-index: 99999;
  background: #fff;
}
.preloader .cssload-speeding-wheel {
  position: absolute;
  top: calc(50% - 3.5px);
  left: calc(50% - 3.5px);
}

.loader,
.loader__figure {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.loader {
  overflow: visible;
  padding-top: 2em;
  height: 0;
  width: 2em;
}

.loader__figure {
  height: 0;
  width: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 0 solid #1976d2;
  border-radius: 50%;
  -webkit-animation: loader-figure 1.15s infinite
    cubic-bezier(0.215, 0.61, 0.355, 1);
  -moz-animation: loader-figure 1.15s infinite
    cubic-bezier(0.215, 0.61, 0.355, 1);
  animation: loader-figure 1.15s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
}

.loader__label {
  float: left;
  margin-left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  margin: 0.5em 0 0 50%;
  font-size: 0.875em;
  letter-spacing: 0.1em;
  line-height: 1.5em;
  color: #1976d2;
  white-space: nowrap;
  -webkit-animation: loader-label 1.15s infinite
    cubic-bezier(0.215, 0.61, 0.355, 1);
  -moz-animation: loader-label 1.15s infinite
    cubic-bezier(0.215, 0.61, 0.355, 1);
  animation: loader-label 1.15s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
}

@-webkit-keyframes loader-figure {
  0% {
    height: 0;
    width: 0;
    background-color: #1976d2;
  }
  29% {
    background-color: #1976d2;
  }
  30% {
    height: 2em;
    width: 2em;
    background-color: transparent;
    border-width: 1em;
    opacity: 1;
  }
  100% {
    height: 2em;
    width: 2em;
    border-width: 0;
    opacity: 0;
    background-color: transparent;
  }
}

@-moz-keyframes loader-figure {
  0% {
    height: 0;
    width: 0;
    background-color: #1976d2;
  }
  29% {
    background-color: #1976d2;
  }
  30% {
    height: 2em;
    width: 2em;
    background-color: transparent;
    border-width: 1em;
    opacity: 1;
  }
  100% {
    height: 2em;
    width: 2em;
    border-width: 0;
    opacity: 0;
    background-color: transparent;
  }
}

@keyframes loader-figure {
  0% {
    height: 0;
    width: 0;
    background-color: #1976d2;
  }
  29% {
    background-color: #1976d2;
  }
  30% {
    height: 2em;
    width: 2em;
    background-color: transparent;
    border-width: 1em;
    opacity: 1;
  }
  100% {
    height: 2em;
    width: 2em;
    border-width: 0;
    opacity: 0;
    background-color: transparent;
  }
}

@-webkit-keyframes loader-label {
  0% {
    opacity: 0.25;
  }
  30% {
    opacity: 1;
  }
  100% {
    opacity: 0.25;
  }
}

@-moz-keyframes loader-label {
  0% {
    opacity: 0.25;
  }
  30% {
    opacity: 1;
  }
  100% {
    opacity: 0.25;
  }
}

@keyframes loader-label {
  0% {
    opacity: 0.25;
  }
  30% {
    opacity: 1;
  }
  100% {
    opacity: 0.25;
  }
}

/*******************
This is the font import from the google api
*******************/
@font-face {
  font-family: 'Calafia-Regular';
  src:
    url('https://smartpay.wisecare.co.kr/assets/font/Calafia-Regular.eot?')
      format('embedded-opentype'),
    url('https://smartpay.wisecare.co.kr/assets/font/Calafia-Regular.otf')
      format('opentype'),
    url('https://smartpay.wisecare.co.kr/assets/font/Calafia-Regular.woff')
      format('woff'),
    url('https://smartpay.wisecare.co.kr/assets/font/Calafia-Regular.ttf')
      format('truetype'),
    url('https://smartpay.wisecare.co.kr/assets/font/Calafia-Regular.svg')
      format('svg');
  font-weight: normal;
  font-style: normal;
}

/*******************
Global Styles
*******************/
* {
  outline: none;
}

body {
  background: #ffffff;
  font-family: 'Montserrat', sans-serif;
  margin: 0;
  overflow-x: hidden;
  color: #8d97ad;
  font-weight: 300;
}

html {
  position: relative;
  min-height: 100%;
  background: #ffffff;
}

a:hover,
a:focus {
  text-decoration: none;
}

a,
a.link {
  color: #1b1d1f;
}
a.link:hover,
a.link:focus {
  color: #316ce8;
}

a.white-link {
  color: #ffffff;
}
a.white-link:hover,
a.white-link:focus {
  color: #263238;
}

.img-responsive {
  width: 100%;
  height: auto;
  display: inline-block;
}

.img-rounded {
  border-radius: 4px;
}

html body .mdi:before,
html body .mdi-set {
  line-height: initial;
}

/*******************
Headings
*******************/
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  /* color: #222; */
  font-weight: 400;
  /* font-family: "Montserrat", sans-serif;  */
}

h1,
.h1 {
  font-size: 48px;
}

h1,
h2 {
  letter-spacing: -0.04em;
  line-height: 1.35;
}

h2,
.h2 {
  font-size: 42px;
}

h3,
.h3 {
  font-size: 40px;
  line-height: 1.35;
}

#business_text {
  font-size: 3.5vmin;
  line-height: 1.35;
  font-weight: 400;
}

h3,
.h3,
h4,
.h4 {
  letter-spacing: -0.04em;
}

h4,
.h4 {
  font-size: 36px;
  line-height: 1.4;
}

h5,
.h5 {
  font-size: 28px;
  letter-spacing: -0.04em;
}

h5,
.h5,
h6,
.h6 {
  line-height: 1.35;
}

h6,
.h6 {
  font-size: 23px;
  letter-spacing: -0.03em;
}

.display-5 {
  font-size: 3rem;
  line-height: 1.2;
  letter-spacing: -0.8px;
}

.display-6 {
  font-size: 36px;
}

.display-7 {
  font-size: 26px;
}

@media (max-width: 768px) {
  html,
  p,
  textarea {
    font-size: 14px;
  }

  h1,
  .h1 {
    font-size: 30px;
  }

  h2,
  .h2 {
    font-size: 28px;
  }

  h3,
  .h3 {
    font-size: 24px;
  }

  h4,
  .h4 {
    font-size: 20px;
  }

  h5,
  .h5 {
    font-size: 18px;
  }

  h6,
  .h6 {
    font-size: 16px;
  }

  small,
  .small {
    font-size: 14px;
  }
  .display-1 {
    font-size: 5rem;
    font-weight: 300;
    line-height: 1.2;
  }

  .display-2 {
    font-size: 4.5rem;
    font-weight: 300;
    line-height: 1.2;
  }

  .display-3 {
    font-size: 6vmin;
    font-weight: 300;
    line-height: 1.2;
  }

  .display-4 {
    font-size: 2.5rem;
    font-weight: 300;
    line-height: 1.2;
  }
}

.box {
  border-radius: 4px;
  padding: 10px;
}

html body .dl {
  display: inline-block;
}

html body .db {
  display: block;
}

.no-wrap td,
.no-wrap th {
  white-space: nowrap;
}

.circle {
  border-radius: 100%;
}

/*******************
Blockquote
*******************/
html body blockquote {
  border-left: 5px solid #316ce8;
  border: 1px solid rgba(120, 130, 140, 0.13);
  padding: 15px;
}

.clear {
  clear: both;
}

ol li {
  margin: 5px 0;
}

/*******************
Paddings
*******************/
html body .p-0 {
  padding: 0px;
}

html body .p-10 {
  padding: 10px;
}

html body .p-15 {
  padding: 15px;
}

html body .p-20 {
  padding: 20px;
}

html body .p-30 {
  padding: 30px;
}

html body .p-40 {
  padding: 40px;
}

html body .p-l-0 {
  padding-left: 0px;
}

html body .p-l-10 {
  padding-left: 10px;
}

html body .p-l-20 {
  padding-left: 20px;
}

html body .p-r-0 {
  padding-right: 0px;
}

html body .p-r-10 {
  padding-right: 10px;
}

html body .p-r-20 {
  padding-right: 20px;
}

html body .p-r-30 {
  padding-right: 30px;
}

html body .p-r-40 {
  padding-right: 40px;
}

html body .p-t-0 {
  padding-top: 0px;
}

html body .p-t-10 {
  padding-top: 10px;
}

html body .p-t-20 {
  padding-top: 20px;
}

html body .p-t-30 {
  padding-top: 30px;
}

html body .p-b-0 {
  padding-bottom: 0px;
}

html body .p-b-5 {
  padding-bottom: 5px;
}

html body .p-b-10 {
  padding-bottom: 10px;
}

html body .p-b-20 {
  padding-bottom: 20px;
}

html body .p-b-30 {
  padding-bottom: 30px;
}

html body .p-b-40 {
  padding-bottom: 40px;
}

/*******************
Margin
*******************/
html body .m-0 {
  margin: 0px;
}

html body .m-l-5 {
  margin-left: 5px;
}

html body .m-l-10 {
  margin-left: 10px;
}

html body .m-l-15 {
  margin-left: 15px;
}

html body .m-l-20 {
  margin-left: -20px;
}

html body .m-l-30 {
  margin-left: 30px;
}

html body .m-l-40 {
  margin-left: 40px;
}

html body .m-r-5 {
  margin-right: 5px;
}

html body .m-r-10 {
  margin-right: 10px;
}

html body .m-r-15 {
  margin-right: 15px;
}

html body .m-r-20 {
  margin-right: 20px;
}

html body .m-r-30 {
  margin-right: 30px;
}

html body .m-r-40 {
  margin-right: 40px;
}

html body .m-t-0 {
  margin-top: 0px;
}

html body .m-t-5 {
  margin-top: 5px;
}

html body .m-t-10 {
  margin-top: 10px;
}

html body .m-t-15 {
  margin-top: 15px;
}

html body .m-t-20 {
  margin-top: 20px;
}

html body .m-t-30 {
  margin-top: 30px;
}

html body .m-t-40 {
  margin-top: 40px;
}

html body .m-b-0 {
  margin-bottom: 0px;
}

html body .m-b-5 {
  margin-bottom: 5px;
}

html body .m-b-10 {
  margin-bottom: 10px;
}

html body .m-b-15 {
  margin-bottom: 15px;
}

html body .m-b-20 {
  margin-bottom: 20px;
}

html body .m-b-30 {
  margin-bottom: 30px;
}

html body .m-b-40 {
  margin-bottom: 40px;
}

/*******************
vertical alignment
*******************/
html body .vt {
  vertical-align: top;
}

html body .vm {
  vertical-align: middle;
}

html body .vb {
  vertical-align: bottom;
}

/*******************
Opacity
*******************/
.op-8 {
  opacity: 0.8;
}

.op-7 {
  opacity: 0.7;
}

.op-5 {
  opacity: 0.5;
}

.op-3 {
  opacity: 0.3;
}

/*******************
font weight
*******************/
html body .font-bold {
  font-weight: 700;
}

html body .font-semibold {
  font-weight: 600;
}

html body .font-normal {
  font-weight: normal;
}

html body .font-light {
  font-weight: 300;
}

html body .font-medium {
  font-weight: 500;
}

html body .font-16 {
  font-size: 16px;
}

html body .font-14 {
  font-size: 14px;
}

html body .font-13 {
  font-size: 13px;
}

html body .font-10 {
  font-size: 10px;
}

html body .font-18 {
  font-size: 18px;
}

html body .font-20 {
  font-size: 20px;
}

html body .font-stylish {
  font-family: 'Calafia-Regular';
}

/*******************
Border
*******************/
html body .b-0 {
  border: none !important;
}

html body .b-r {
  border-right: 1px solid rgba(120, 130, 140, 0.13);
}

html body .b-l {
  border-left: 1px solid rgba(120, 130, 140, 0.13);
}

html body .b-b {
  border-bottom: 1px solid rgba(120, 130, 140, 0.13);
}

html body .b-t {
  border-top: 1px solid rgba(120, 130, 140, 0.13);
}

html body .b-all {
  border: 1px solid rgba(120, 130, 140, 0.13) !important;
}

/*******************
Thumb size
*******************/
.thumb-sm {
  height: 32px;
  width: 32px;
}

.thumb-md {
  height: 48px;
  width: 48px;
}

.thumb-lg {
  height: 88px;
  width: 88px;
}

.hide {
  display: none;
}

.img-circle {
  border-radius: 100%;
}

.radius {
  border-radius: 4px;
}

.text-white {
  color: #ffffff !important;
}

.text-danger {
  color: #ff4d7e !important;
}

.text-muted {
  color: #8d97ad !important;
}

.text-warning {
  color: #fec500 !important;
}

.text-success {
  color: #2cdd9b !important;
}

.text-info {
  color: #188ef4 !important;
}

.text-inverse {
  color: #1b1d1f !important;
}

.text-success-gradiant {
  background: #2cdd9b;
  background: -webkit-linear-gradient(
    legacy-direction(to right),
    #2cdd9b 0%,
    #1dc8cc 100%
  );
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#2cdd9b),
    to(#1dc8cc)
  );
  background: -webkit-linear-gradient(left, #2cdd9b 0%, #1dc8cc 100%);
  background: -o-linear-gradient(left, #2cdd9b 0%, #1dc8cc 100%);
  background: linear-gradient(to right, #2cdd9b 0%, #1dc8cc 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
}

.text-info-gradiant {
  background: #188ef4;
  background: -webkit-linear-gradient(
    legacy-direction(to right),
    #188ef4 0%,
    #316ce8 100%
  );
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#188ef4),
    to(#316ce8)
  );
  background: -webkit-linear-gradient(left, #188ef4 0%, #316ce8 100%);
  background: -o-linear-gradient(left, #188ef4 0%, #316ce8 100%);
  background: linear-gradient(to right, #188ef4 0%, #316ce8 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
}

html body .text-blue {
  color: #02bec9;
}

html body .text-purple {
  color: #7460ee;
}

html body .text-primary {
  color: #316ce8 !important;
}

html body .text-megna {
  color: #1dc8cd;
}

html body .text-dark {
  color: #8d97ad;
}

html body .text-themecolor {
  color: #316ce8;
}

.bg-primary {
  background-color: #316ce8 !important;
}

.bg-success {
  background-color: #2cdd9b !important;
}

.bg-info {
  background-color: #188ef4 !important;
}

.bg-warning {
  background-color: #fec500 !important;
}

.bg-danger {
  background-color: #ff4d7e !important;
}

.bg-orange {
  background-color: #ff6a5b !important;
}

.bg-yellow {
  background-color: #fed700;
}

.bg-facebook {
  background-color: #3b5a9a;
}

.bg-twitter {
  background-color: #56adf2;
}

.bg-success-gradiant {
  background: #2cdd9b;
  background: -webkit-linear-gradient(
    legacy-direction(to right),
    #2cdd9b 0%,
    #1dc8cc 100%
  );
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#2cdd9b),
    to(#1dc8cc)
  );
  background: -webkit-linear-gradient(left, #2cdd9b 0%, #1dc8cc 100%);
  background: -o-linear-gradient(left, #2cdd9b 0%, #1dc8cc 100%);
  background: linear-gradient(to right, #2cdd9b 0%, #1dc8cc 100%);
}

.bg-info-gradiant {
  background: #188ef4;
  background: -webkit-linear-gradient(
    legacy-direction(to right),
    #188ef4 0%,
    #316ce8 100%
  );
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#188ef4),
    to(#316ce8)
  );
  background: -webkit-linear-gradient(left, #188ef4 0%, #316ce8 100%);
  background: -o-linear-gradient(left, #188ef4 0%, #316ce8 100%);
  background: linear-gradient(to right, #188ef4 0%, #316ce8 100%);
}

.bg-danger-gradiant {
  background: #ff4d7e;
  background: -webkit-linear-gradient(
    legacy-direction(to right),
    #ff4d7e 0%,
    #ff6a5b 100%
  );
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#ff4d7e),
    to(#ff6a5b)
  );
  background: -webkit-linear-gradient(left, #ff4d7e 0%, #ff6a5b 100%);
  background: -o-linear-gradient(left, #ff4d7e 0%, #ff6a5b 100%);
  background: linear-gradient(to right, #ff4d7e 0%, #ff6a5b 100%);
}

html body .bg-megna {
  background-color: #1dc8cd;
}

html body .bg-theme {
  background-color: #316ce8;
}

html body .bg-inverse {
  background-color: #1b1d1f;
}

html body .bg-purple {
  background-color: #7460ee;
}

html body .bg-light {
  background-color: #f4f8fa;
}

html body .bg-light-primary {
  background-color: #f1effd;
}

html body .bg-light-success {
  background-color: #e8fdeb;
}

html body .bg-light-info {
  background-color: #cfecfe;
}

html body .bg-light-extra {
  background-color: #ebf3f5;
}

html body .bg-light-warning {
  background-color: #fff8ec;
}

html body .bg-light-danger {
  background-color: #f9e7eb;
}

html body .bg-light-inverse {
  background-color: #f6f6f6;
}

html body .bg-white {
  background-color: #ffffff;
}

/*******************
Rounds
*******************/
.round {
  line-height: 48px;
  color: #ffffff;
  width: 50px;
  height: 50px;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  border-radius: 100%;
  background: #188ef4;
}
.round img {
  border-radius: 100%;
}

.round-lg {
  line-height: 65px;
  width: 60px;
  height: 60px;
  font-size: 30px;
}

.round.round-info {
  background: #188ef4;
}

.round.round-warning {
  background: #fec500;
}

.round.round-danger {
  background: #ff4d7e;
}

.round.round-success {
  background: #2cdd9b;
}

.round.round-primary {
  background: #316ce8;
}

/*******************
Labels
*******************/
.label {
  padding: 3px 15px;
  color: #ffffff;
  font-weight: 400;
  border-radius: 4px;
  font-size: 75%;
}

.label-rounded {
  border-radius: 60px;
}

.label-custom {
  background-color: #1dc8cd;
}

.label-success {
  background-color: #2cdd9b;
}

.label-info {
  background-color: #188ef4;
}

.label-warning {
  background-color: #fec500;
}

.label-danger {
  background-color: #ff4d7e;
}

.label-megna {
  background-color: #1dc8cd;
}

.label-primary {
  background-color: #316ce8;
}

.label-purple {
  background-color: #7460ee;
}

.label-red {
  background-color: #fb3a3a;
}

.label-inverse {
  background-color: #1b1d1f;
}

.label-default {
  background-color: #f4f8fa;
}

.label-white {
  background-color: #ffffff;
}

.label-light-success {
  background-color: #e8fdeb;
  color: #2cdd9b;
}

.label-light-info {
  background-color: #cfecfe;
  color: #188ef4;
}

.label-light-warning {
  background-color: #fff8ec;
  color: #fec500;
}

.label-light-danger {
  background-color: #f9e7eb;
  color: #ff4d7e;
}

.label-light-megna {
  background-color: #e0f2f4;
  color: #1dc8cd;
}

.label-light-primary {
  background-color: #f1effd;
  color: #316ce8;
}

.label-light-inverse {
  background-color: #f6f6f6;
  color: #1b1d1f;
}

/*******************
 Badge
******************/
.badge {
  font-weight: 400;
}

.badge-xs {
  font-size: 9px;
}

.badge-xs,
.badge-sm {
  -webkit-transform: translate(0, -2px);
  -ms-transform: translate(0, -2px);
  -o-transform: translate(0, -2px);
  transform: translate(0, -2px);
}

.badge-success {
  background-color: #2cdd9b;
}

.badge-info {
  background-color: #188ef4;
}

.badge-primary {
  background-color: #316ce8;
}

.badge-warning {
  background-color: #fec500;
}

.badge-danger {
  background-color: #ff4d7e;
}

.badge-purple {
  background-color: #7460ee;
}

.badge-red {
  background-color: #fb3a3a;
}

.badge-inverse {
  background-color: #1b1d1f;
}

/*******************
List-style-none
******************/
ul.list-style-none {
  margin: 0px;
  padding: 0px;
}
ul.list-style-none li {
  list-style: none;
}
ul.list-style-none li a {
  color: #8d97ad;
  padding: 8px 0px;
  display: block;
  text-decoration: none;
}
ul.list-style-none li a:hover {
  color: #316ce8;
}

/*******************
dropdown-item
******************/
.dropdown-item {
  padding: 8px 1rem;
  color: #8d97ad;
}

/*******************
Buttons
******************/
.btn {
  color: #ffffff;
  padding: 10px 25px;
  cursor: pointer;
  -webkit-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.1);
  -webkit-transition: 0s;
  -o-transition: 0s;
  transition: 0s;
}
.btn:hover {
  color: #ffffff;
}
.btn:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-link {
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #263238;
  padding: 10px 15px;
}
.btn-link .underline {
  border-bottom: 1px solid #263238;
}
.btn-link:hover {
  color: #2cdd9b;
}

.btn-light {
  color: #263238;
}
.btn-light:hover {
  background: #263238;
  color: #ffffff;
  border-color: #263238;
}

.btn-lg {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
}

.btn-md {
  padding: 15px 45px;
  font-size: 16px;
}

.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 12px;
}

.btn-xs {
  padding: 0.25rem 0.5rem;
  font-size: 10px;
}

.btn-circle {
  border-radius: 100%;
  width: 40px;
  height: 40px;
  padding: 10px;
}
.btn-circle.btn-md {
  padding: 18px 0px;
  width: 60px;
  height: 60px;
  font-size: 20px;
}

.btn-circle.btn-sm {
  width: 35px;
  height: 35px;
  padding: 8px 10px;
  font-size: 14px;
}

.btn-circle.btn-lg {
  width: 70px;
  height: 70px;
  padding: 24px 15px;
  font-size: 20px;
  line-height: 23px;
}

.btn-circle.btn-xl {
  width: 70px;
  height: 70px;
  padding: 14px 15px;
  font-size: 24px;
}

.btn-rounded {
  border-radius: 60px;
  -webkit-border-radius: 60px;
}

.btn-arrow {
  position: relative;
}
.btn-arrow span {
  display: inline-block;
  position: relative;
  -webkit-transition: all 300ms ease-out;
  -o-transition: all 300ms ease-out;
  transition: all 300ms ease-out;
  will-change: transform;
}
.btn-arrow:hover span,
.btn-arrow:focus span {
  -webkit-transform: translate3d(-1rem, 0, 0);
  transform: translate3d(-1rem, 0, 0);
}
.btn-arrow i {
  position: absolute;
  width: 1.1em;
  right: 0px;
  right: 0rem;
  opacity: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: all 300ms ease-out;
  -o-transition: all 300ms ease-out;
  transition: all 300ms ease-out;
  will-change: right, opacity;
}
.btn-arrow:hover i,
.btn-arrow:focus i {
  opacity: 1;
  right: -2rem;
}

.btn-secondary,
.btn-secondary.disabled {
  -webkit-transition: 0.2s ease-in;
  -o-transition: 0.2s ease-in;
  transition: 0.2s ease-in;
  background-color: #ffffff;
  color: #8d97ad;
}
.btn-secondary:hover,
.btn-secondary.disabled:hover {
  color: #ffffff !important;
}
.btn-secondary.active,
.btn-secondary:active,
.btn-secondary:focus,
.btn-secondary.disabled.active,
.btn-secondary.disabled:active,
.btn-secondary.disabled:focus {
  color: #ffffff !important;
  background: #263238;
  border-color: #263238;
}

.btn-primary,
.btn-primary.disabled {
  background: #316ce8;
  border: 1px solid #316ce8;
  -webkit-transition: 0.2s ease-in;
  -o-transition: 0.2s ease-in;
  transition: 0.2s ease-in;
}
.btn-primary:hover,
.btn-primary.disabled:hover {
  background: #345bcb;
  border: 1px solid #345bcb;
}
.btn-primary.active,
.btn-primary:active,
.btn-primary:focus,
.btn-primary.disabled.active,
.btn-primary.disabled:active,
.btn-primary.disabled:focus {
  background: #345bcb;
}

.btn-themecolor,
.btn-themecolor.disabled {
  background: #316ce8;
  color: #ffffff;
  border: 1px solid #316ce8;
}
.btn-themecolor:hover,
.btn-themecolor.disabled:hover {
  background: #028ee1;
  border: 1px solid #028ee1;
}
.btn-themecolor.active,
.btn-themecolor:active,
.btn-themecolor:focus,
.btn-themecolor.disabled.active,
.btn-themecolor.disabled:active,
.btn-themecolor.disabled:focus {
  background: #028ee1;
}

.btn-success,
.btn-success.disabled {
  background: #2cdd9b;
  border: 1px solid #2cdd9b;
  color: #ffffff;
}
.btn-success:hover,
.btn-success.disabled:hover {
  background: #1dc8cc;
  border: 1px solid #1dc8cc;
}
.btn-success.active,
.btn-success:active,
.btn-success:focus,
.btn-success.disabled.active,
.btn-success.disabled:active,
.btn-success.disabled:focus {
  background: #1dc8cc;
}

.btn-info,
.btn-info.disabled {
  background: #188ef4;
  border: 1px solid #188ef4;
  -webkit-transition: 0.2s ease-in;
  -o-transition: 0.2s ease-in;
  transition: 0.2s ease-in;
}
.btn-info:hover,
.btn-info.disabled:hover {
  background: #2c73cb;
  border: 1px solid #2c73cb;
}
.btn-info.active,
.btn-info:active,
.btn-info:focus,
.btn-info.disabled.active,
.btn-info.disabled:active,
.btn-info.disabled:focus {
  background: #2c73cb;
}

.btn-warning,
.btn-warning.disabled {
  background: #fec500;
  color: #ffffff;
  border: 1px solid #fec500;
}
.btn-warning:hover,
.btn-warning.disabled:hover {
  background: #dcaf17;
  color: #ffffff;
  border: 1px solid #dcaf17;
}
.btn-warning.active,
.btn-warning:active,
.btn-warning:focus,
.btn-warning.disabled.active,
.btn-warning.disabled:active,
.btn-warning.disabled:focus {
  background: #dcaf17;
  color: #ffffff;
}

.btn-danger,
.btn-danger.disabled {
  background: #ff4d7e;
  border: 1px solid #ff4d7e;
  -webkit-transition: 0.2s ease-in;
  -o-transition: 0.2s ease-in;
  transition: 0.2s ease-in;
}
.btn-danger:hover,
.btn-danger.disabled:hover {
  background: #d73e6b;
  border: 1px solid #d73e6b;
}
.btn-danger.active,
.btn-danger:active,
.btn-danger:focus,
.btn-danger.disabled.active,
.btn-danger.disabled:active,
.btn-danger.disabled:focus {
  background: #d73e6b;
}

.btn-inverse,
.btn-inverse.disabled {
  background: #1b1d1f;
  border: 1px solid #1b1d1f;
  color: #ffffff;
}
.btn-inverse:hover,
.btn-inverse.disabled:hover {
  background: #232a37;
  color: #ffffff;
  border: 1px solid #232a37;
}
.btn-inverse.active,
.btn-inverse:active,
.btn-inverse:focus,
.btn-inverse.disabled.active,
.btn-inverse.disabled:active,
.btn-inverse.disabled:focus {
  background: #232a37;
  color: #ffffff;
}

.btn-red,
.btn-red.disabled {
  background: #fb3a3a;
  border: 1px solid #fb3a3a;
  color: #ffffff;
}
.btn-red:hover,
.btn-red.disabled:hover {
  border: 1px solid #d61f1f;
  background: #d61f1f;
}
.btn-red.active,
.btn-red:active,
.btn-red:focus,
.btn-red.disabled.active,
.btn-red.disabled:active,
.btn-red.disabled:focus {
  background: #d73e6b;
}

.btn-success-gradiant {
  background: #2cdd9b;
  background: -webkit-linear-gradient(
    legacy-direction(to right),
    #2cdd9b 0%,
    #1dc8cc 100%
  );
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#2cdd9b),
    to(#1dc8cc)
  );
  background: -webkit-linear-gradient(left, #2cdd9b 0%, #1dc8cc 100%);
  background: -o-linear-gradient(left, #2cdd9b 0%, #1dc8cc 100%);
  background: linear-gradient(to right, #2cdd9b 0%, #1dc8cc 100%);
  border: 0px;
}
.btn-success-gradiant:hover {
  background: #1dc8cc;
  background: -webkit-linear-gradient(
    legacy-direction(to right),
    #1dc8cc 0%,
    #2cdd9b 100%
  );
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#1dc8cc),
    to(#2cdd9b)
  );
  background: -webkit-linear-gradient(left, #1dc8cc 0%, #2cdd9b 100%);
  background: -o-linear-gradient(left, #1dc8cc 0%, #2cdd9b 100%);
  background: linear-gradient(to right, #1dc8cc 0%, #2cdd9b 100%);
}
.btn-success-gradiant.active,
.btn-success-gradiant:active,
.btn-success-gradiant:focus {
  -webkit-box-shadow: 0px;
  box-shadow: 0px;
  opacity: 1;
}

.btn-danger-gradiant {
  background: #ff4d7e;
  background: -webkit-linear-gradient(
    legacy-direction(to right),
    #ff4d7e 0%,
    #ff6a5b 100%
  );
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#ff4d7e),
    to(#ff6a5b)
  );
  background: -webkit-linear-gradient(left, #ff4d7e 0%, #ff6a5b 100%);
  background: -o-linear-gradient(left, #ff4d7e 0%, #ff6a5b 100%);
  background: linear-gradient(to right, #ff4d7e 0%, #ff6a5b 100%);
  border: 0px;
}
.btn-danger-gradiant:hover {
  background: #ff6a5b;
  background: -webkit-linear-gradient(
    legacy-direction(to right),
    #ff6a5b 0%,
    #ff4d7e 100%
  );
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#ff6a5b),
    to(#ff4d7e)
  );
  background: -webkit-linear-gradient(left, #ff6a5b 0%, #ff4d7e 100%);
  background: -o-linear-gradient(left, #ff6a5b 0%, #ff4d7e 100%);
  background: linear-gradient(to right, #ff6a5b 0%, #ff4d7e 100%);
}
.btn-danger-gradiant.active,
.btn-danger-gradiant:active,
.btn-danger-gradiant:focus {
  -webkit-box-shadow: 0px;
  box-shadow: 0px;
  opacity: 1;
}

.btn-info-gradiant {
  background: #188ef4;
  background: -webkit-linear-gradient(
    legacy-direction(to right),
    #188ef4 0%,
    #316ce8 100%
  );
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#188ef4),
    to(#316ce8)
  );
  background: -webkit-linear-gradient(left, #188ef4 0%, #316ce8 100%);
  background: -o-linear-gradient(left, #188ef4 0%, #316ce8 100%);
  background: linear-gradient(to right, #188ef4 0%, #316ce8 100%);
  border: 0px;
  color: #ffffff;
}
.btn-info-gradiant:hover {
  background: #316ce8;
  background: -webkit-linear-gradient(
    legacy-direction(to right),
    #316ce8 0%,
    #188ef4 100%
  );
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#316ce8),
    to(#188ef4)
  );
  background: -webkit-linear-gradient(left, #316ce8 0%, #188ef4 100%);
  background: -o-linear-gradient(left, #316ce8 0%, #188ef4 100%);
  background: linear-gradient(to right, #316ce8 0%, #188ef4 100%);
}
.btn-info-gradiant.active,
.btn-info-gradiant:active,
.btn-info-gradiant:focus {
  -webkit-box-shadow: 0px;
  box-shadow: 0px;
  opacity: 1;
}

.btn-outline-secondary {
  text-decoration: none;
  background-color: #ffffff;
  color: #727b84;
  -webkit-transition: 0.2s ease-in;
  -o-transition: 0.2s ease-in;
  transition: 0.2s ease-in;
}
.btn-outline-secondary.active,
.btn-outline-secondary:active,
.btn-outline-secondary:focus {
  background: #263238;
}

.btn-outline-primary {
  color: #316ce8;
  background-color: #ffffff;
  border-color: #316ce8;
  -webkit-transition: 0.2s ease-in;
  -o-transition: 0.2s ease-in;
  transition: 0.2s ease-in;
}
.btn-outline-primary:hover,
.btn-outline-primary:focus,
.btn-outline-primary.focus {
  background: #316ce8;
  color: #ffffff;
  border-color: #316ce8;
}
.btn-outline-primary.active,
.btn-outline-primary:active,
.btn-outline-primary:focus {
  background: #345bcb;
}

.btn-outline-success,
a.btn-outline-success {
  color: #2cdd9b;
  background-color: transparent;
  border-color: #2cdd9b;
  -webkit-transition: 0.2s ease-in;
  -o-transition: 0.2s ease-in;
  transition: 0.2s ease-in;
}
.btn-outline-success:hover,
.btn-outline-success:focus,
.btn-outline-success.focus,
a.btn-outline-success:hover,
a.btn-outline-success:focus,
a.btn-outline-success.focus {
  background: #2cdd9b;
  border-color: #2cdd9b;
  color: #ffffff;
}
.btn-outline-success.active,
.btn-outline-success:active,
.btn-outline-success:focus,
a.btn-outline-success.active,
a.btn-outline-success:active,
a.btn-outline-success:focus {
  background: #1dc8cc;
}

.btn-outline-info {
  color: #188ef4;
  background-color: transparent;
  border-color: #188ef4;
  -webkit-transition: 0.2s ease-in;
  -o-transition: 0.2s ease-in;
  transition: 0.2s ease-in;
}
.btn-outline-info:hover,
.btn-outline-info:focus,
.btn-outline-info.focus {
  background: #188ef4;
  border-color: #188ef4;
  color: #ffffff;
}
.btn-outline-info.active,
.btn-outline-info:active,
.btn-outline-info:focus {
  background: #2c73cb;
}

.btn-outline-warning {
  color: #fec500;
  background-color: transparent;
  border-color: #fec500;
  -webkit-transition: 0.2s ease-in;
  -o-transition: 0.2s ease-in;
  transition: 0.2s ease-in;
}
.btn-outline-warning:hover,
.btn-outline-warning:focus,
.btn-outline-warning.focus {
  background: #fec500;
  border-color: #fec500;
  color: #ffffff;
}
.btn-outline-warning.active,
.btn-outline-warning:active,
.btn-outline-warning:focus {
  background: #dcaf17;
}

.btn-outline-danger {
  color: #ff4d7e;
  background-color: transparent;
  border-color: #ff4d7e;
  -webkit-transition: 0.2s ease-in;
  -o-transition: 0.2s ease-in;
  transition: 0.2s ease-in;
}
.btn-outline-danger:hover,
.btn-outline-danger:focus,
.btn-outline-danger.focus {
  background: #ff4d7e;
  border-color: #ff4d7e;
  color: #ffffff;
}
.btn-outline-danger.active,
.btn-outline-danger:active,
.btn-outline-danger:focus {
  background: #d73e6b;
}

.btn-outline-red {
  color: #fb3a3a;
  background-color: transparent;
  border-color: #fb3a3a;
}
.btn-outline-red:hover,
.btn-outline-red:focus,
.btn-outline-red.focus {
  background: #fb3a3a;
  border-color: #fb3a3a;
  color: #ffffff;
}
.btn-outline-red.active,
.btn-outline-red:active,
.btn-outline-red:focus {
  background: #d73e6b;
}

.btn-outline-inverse {
  color: #1b1d1f;
  background-color: transparent;
  border-color: #1b1d1f;
}
.btn-outline-inverse:hover,
.btn-outline-inverse:focus,
.btn-outline-inverse.focus {
  background: #1b1d1f;
  border-color: #1b1d1f;
  color: #ffffff;
}

.btn-outline-light:hover {
  color: #263238 !important;
}

.btn-primary.active.focus,
.btn-primary.active:focus,
.btn-primary.active:hover,
.btn-primary.focus:active,
.btn-primary:active:focus,
.btn-primary:active:hover,
.open > .dropdown-toggle.btn-primary.focus,
.open > .dropdown-toggle.btn-primary:focus,
.open > .dropdown-toggle.btn-primary:hover,
.btn-primary.focus,
.btn-primary:focus {
  background-color: #345bcb;
  border: 1px solid #345bcb;
}

.btn-success.active.focus,
.btn-success.active:focus,
.btn-success.active:hover,
.btn-success.focus:active,
.btn-success:active:focus,
.btn-success:active:hover,
.open > .dropdown-toggle.btn-success.focus,
.open > .dropdown-toggle.btn-success:focus,
.open > .dropdown-toggle.btn-success:hover,
.btn-success.focus,
.btn-success:focus {
  background-color: #1dc8cc;
  border: 1px solid #1dc8cc;
}

.btn-info.active.focus,
.btn-info.active:focus,
.btn-info.active:hover,
.btn-info.focus:active,
.btn-info:active:focus,
.btn-info:active:hover,
.open > .dropdown-toggle.btn-info.focus,
.open > .dropdown-toggle.btn-info:focus,
.open > .dropdown-toggle.btn-info:hover,
.btn-info.focus,
.btn-info:focus {
  background-color: #2c73cb;
  border: 1px solid #2c73cb;
}

.btn-warning.active.focus,
.btn-warning.active:focus,
.btn-warning.active:hover,
.btn-warning.focus:active,
.btn-warning:active:focus,
.btn-warning:active:hover,
.open > .dropdown-toggle.btn-warning.focus,
.open > .dropdown-toggle.btn-warning:focus,
.open > .dropdown-toggle.btn-warning:hover,
.btn-warning.focus,
.btn-warning:focus {
  background-color: #dcaf17;
  border: 1px solid #dcaf17;
}

.btn-danger.active.focus,
.btn-danger.active:focus,
.btn-danger.active:hover,
.btn-danger.focus:active,
.btn-danger:active:focus,
.btn-danger:active:hover,
.open > .dropdown-toggle.btn-danger.focus,
.open > .dropdown-toggle.btn-danger:focus,
.open > .dropdown-toggle.btn-danger:hover,
.btn-danger.focus,
.btn-danger:focus {
  background-color: #d73e6b;
  border: 1px solid #d73e6b;
}

.btn-inverse:hover,
.btn-inverse:focus,
.btn-inverse:active,
.btn-inverse.active,
.btn-inverse.focus,
.btn-inverse:active,
.btn-inverse:focus,
.btn-inverse:hover,
.open > .dropdown-toggle.btn-inverse {
  background-color: #232a37;
  border: 1px solid #232a37;
}

.btn-red:hover,
.btn-red:focus,
.btn-red:active,
.btn-red.active,
.btn-red.focus,
.btn-red:active,
.btn-red:focus,
.btn-red:hover,
.open > .dropdown-toggle.btn-red {
  background-color: #d61f1f;
  border: 1px solid #d61f1f;
  color: #ffffff;
}

.btn span.btn-devider {
  display: inline-block;
  padding-left: 10px;
}

/*-------------------*/
/*On off switch*/
/*-------------------*/
.onoffswitch {
  position: relative;
  width: 70px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  margin: 0 auto;
}

.onoffswitch-checkbox {
  display: none;
}

.onoffswitch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 2px solid transparent;
  border-radius: 20px;
}

.onoffswitch-inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  -webkit-transition: margin 0.3s ease-in 0s;
  -o-transition: margin 0.3s ease-in 0s;
  transition: margin 0.3s ease-in 0s;
}
.onoffswitch-inner:before,
.onoffswitch-inner:after {
  display: block;
  float: left;
  width: 50%;
  height: 30px;
  padding: 0;
  line-height: 30px;
  font-size: 14px;
  color: white;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.onoffswitch-inner:before {
  content: '';
  padding-right: 27px;
  background-color: #2cdd9b;
  color: #ffffff;
}
.onoffswitch-inner:after {
  content: '';
  padding-right: 24px;
  background-color: #1b1d1f;
  color: #999999;
  text-align: right;
}

.onoffswitch-switch {
  display: block;
  width: 23px;
  margin: 6px;
  background: #ffffff;
  height: 23px;
  position: absolute;
  top: -1px;
  bottom: 0;
  right: 35px;
  border-radius: 20px;
  -webkit-transition: all 0.3s ease-in 0s;
  -o-transition: all 0.3s ease-in 0s;
  transition: all 0.3s ease-in 0s;
}

.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
  margin-left: 0;
}

.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
  right: 0px;
}

/*-------------------*/
/*Bootstrap 4 hack*/
/*-------------------*/
.card-columns {
  -webkit-column-gap: 30px;
  column-gap: 30px;
}
.card-columns .card {
  margin-bottom: 30px;
}

.invisible {
  visibility: hidden !important;
}

.hidden-xs-up {
  display: none !important;
}

@media (max-width: 575px) {
  .hidden-xs-down {
    display: none !important;
  }
}

@media (min-width: 576px) {
  .hidden-sm-up {
    display: none !important;
  }
}

@media (max-width: 767px) {
  .hidden-sm-down {
    display: none !important;
  }
}

@media (min-width: 768px) {
  .hidden-md-up {
    display: none !important;
  }
}

@media (max-width: 991px) {
  .hidden-md-down {
    display: none !important;
  }
}

@media (min-width: 992px) {
  .hidden-lg-up {
    display: none !important;
  }
}

@media (max-width: 1199px) {
  .hidden-lg-down {
    display: none !important;
  }
}

@media (min-width: 1200px) {
  .hidden-xl-up {
    display: none !important;
  }
}

.hidden-xl-down {
  display: none !important;
}

/*
Template Name: Wrakit UI Kit
Author: wrappixel
Email: niravjoshi87@gmail.com
File: scss
*/
/*******************
Topbar
*******************/
.topbar {
  padding: 0px;
  -webkit-transition: 0.2s ease-in;
  -o-transition: 0.2s ease-in;
  transition: 0.2s ease-in;
  width: 100%;
  position: fixed;
  z-index: 20;
}
.topbar.fixed-header {
  -webkit-box-shadow: 0px 0px 30px rgba(115, 128, 157, 0.1);
  box-shadow: 0px 0px 30px rgba(115, 128, 157, 0.1);
  -webkit-transition: 0.2s ease-in;
  -o-transition: 0.2s ease-in;
  transition: 0.2s ease-in;
  background: #188ef4;
}
.topbar.fixed-header .navbar .navbar-brand img {
  height: 50px;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translateZ(0) scale(1, 1);
}

/*******************
Header 6
*******************/
.header6 .navbar-toggler {
  color: #ffffff;
}

.header6 .navbar-brand {
  line-height: 80px;
}

.h6-nav-bar {
  padding: 0px;
}
.h6-nav-bar .navbar-nav .nav-link {
  padding: 35px 15px;
  color: rgba(255, 255, 255, 0.5);
}
.h6-nav-bar .navbar-nav .nav-item:hover .nav-link,
.h6-nav-bar .navbar-nav .nav-item.active .nav-link {
  color: white;
}
.h6-nav-bar .act-buttons .btn {
  margin: 10px 0 10px 10px;
}

@media (max-width: 1023px) {
  .h6-nav-bar .navbar-nav .nav-link {
    padding: 15px 15px;
  }
  .h6-nav-bar .act-buttons .btn {
    margin: 10px 0 10px 0px;
  }
}

/*******************
Main & Page wrapper
*******************/
.page-wrapper {
  padding-top: 0px;
}

#main-wrapper {
  overflow: hidden;
}

/*******************
container fluid
*******************/
.container-fluid {
  /* padding: 0px;  */
}

/*******************
footer
*******************/
.footer {
  padding: 20px 0;
  text-align: center;
}

/*******************
Fix-width
*******************/
.fix-width {
  width: 100%;
  max-width: 1170px;
  margin: 0 auto;
  padding: 0 15px;
}

/*******************
Spacer
*******************/
.spacer {
  padding: 100px 0;
}

.mini-spacer {
  padding: 40px 0;
}

@media (max-width: 574px) {
  .spacer {
    padding: 50px 0;
  }
}

/*******************
Title
*******************/
.title {
  margin: 20px 0 15px;
}

/*******************
Subtitle
*******************/
.subtitle {
  color: #8d97ad;
  line-height: 24px;
}

/*******************
card
*******************/
.card {
  border: 0px;
  margin-bottom: 30px;
}
.card.card-shadow {
  -webkit-box-shadow: 0px 0px 30px rgba(115, 128, 157, 0.1);
  box-shadow: 0px 0px 30px rgba(115, 128, 157, 0.1);
}

.card-group .card {
  border: 1px solid rgba(120, 130, 140, 0.13);
  margin-bottom: 30px;
}

/*******************
Icon space
*******************/
.icon-space {
  margin: 20px 0;
}

.linking {
  color: #1b1d1f;
}
.linking i {
  font-size: 14px;
  margin-left: 10px;
  -webkit-transition: 0.2s ease-in;
  -o-transition: 0.2s ease-in;
  transition: 0.2s ease-in;
}
.linking:hover {
  color: #316ce8;
}
.linking:hover i {
  margin-left: 15px;
}

/*******************
Listing
*******************/
.list-block {
  margin: 0px;
  padding: 0px;
}
.list-block li {
  list-style: none;
  padding: 10px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.list-block li i {
  margin-right: 10px;
  -ms-flex-negative: 0px;
  flex-shrink: 0px;
  margin-top: 3px;
}
.list-block.with-underline li {
  border-bottom: 1px solid rgba(120, 130, 140, 0.13);
  padding: 15px 0;
}

/*******************
Listing
*******************/
.img-inline {
  margin: 0px;
  padding: 0px;
}
.img-inline li {
  list-style: none;
}
.img-inline li.half-width {
  width: 49%;
  display: inline-block;
  vertical-align: top;
}

/*******************
Image shadow
*******************/
.img-shadow {
  -webkit-box-shadow: 0px 0px 30px rgba(115, 128, 157, 0.3);
  box-shadow: 0px 0px 30px rgba(115, 128, 157, 0.3);
}

/*******************
Image shadow
*******************/
.icon-round {
  width: 80px;
  line-height: 80px;
  text-align: center;
  border-radius: 100%;
  display: inline-block;
}

.no-shrink {
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

/*******************
up section
*******************/
.up {
  z-index: 10;
  position: relative;
}

.bt-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 100;
  visibility: hidden;
}

/*******************
both space
*******************/
.both-space {
  margin: 80px 0;
}

/*******************
Side text content
*******************/
.side-content {
  padding: 40px 60px;
}

/*******************
background cover
*******************/
.bg-cover {
  background-size: cover;
  min-height: 200px;
}

/*******************
Owl carousel
*******************/
.owl-carousel {
  display: block;
  width: 100%;
}
.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
}
.owl-carousel .owl-stage {
  position: relative;
}
.owl-carousel .owl-item {
  float: left;
}
.owl-carousel .owl-nav.disabled {
  display: none;
}

/*******************
Max width
*******************/
.max-300 {
  max-width: 300px;
}

.max-400 {
  max-width: 400px;
}

.max-500 {
  max-width: 500px;
}

.max-600 {
  max-width: 600px;
}

/*******************
line sticks
*******************/
.stick-bottom {
  position: relative;
  margin-bottom: 30px;
}
.stick-bottom > span {
  width: 30px;
  height: 2px;
  position: absolute;
  bottom: -7px;
  left: 0px;
  display: inline-block;
}

/*******************
position
*******************/
.po-absolute {
  position: absolute;
}

.po-relative {
  position: relative;
}

/*-------------------*/
/*Bootstrap 4 hack*/
/*-------------------*/
.invisible {
  visibility: hidden !important;
}

.hidden-xs-up {
  display: none !important;
}

@media (max-width: 575px) {
  .hidden-xs-down {
    display: none !important;
  }
}

@media (min-width: 576px) {
  .hidden-sm-up {
    display: none !important;
  }
}

@media (max-width: 767px) {
  .hidden-sm-down {
    display: none !important;
  }
}

@media (min-width: 768px) {
  .hidden-md-up {
    display: none !important;
  }
}

@media (max-width: 991px) {
  .hidden-md-down {
    display: none !important;
  }
}

@media (min-width: 992px) {
  .hidden-lg-up {
    display: none !important;
  }
}

@media (max-width: 1199px) {
  .hidden-lg-down {
    display: none !important;
  }
}

@media (min-width: 1200px) {
  .hidden-xl-up {
    display: none !important;
  }
}

.hidden-xl-down {
  display: none !important;
}

/*************/
/* Dropdown*/
/*************/
.navbar-nav .dropdown-toggle::after {
  display: none;
}

.navbar-nav .dropdown-menu.b-none {
  border: 0px;
  -webkit-box-shadow: 0px 0px 30px rgba(115, 128, 157, 0.1);
  box-shadow: 0px 0px 30px rgba(115, 128, 157, 0.1);
}

.navbar-nav {
  font-weight: 400;
}

.navbar-nav .dropdown-menu.b-none {
  border: 0px;
  -webkit-box-shadow: 0px 0px 30px rgba(115, 128, 157, 0.1);
  box-shadow: 0px 0px 30px rgba(115, 128, 157, 0.1);
}

.pre-scroll {
  position: relative;
}

.mega-dropdown {
  position: static;
}
.mega-dropdown .dropdown-menu {
  width: 100%;
  padding: 30px;
  margin-top: 0px;
  overflow: hidden;
}
.mega-dropdown .list-style-none {
  font-size: 14px;
}
.mega-dropdown .inside-bg {
  margin: -30px 0 -30px -30px;
}
.mega-dropdown .inside-bg-right {
  margin: -30px -30px -30px 0;
}
.mega-dropdown .bg-img {
  background-size: cover;
  height: 100%;
  padding: 30px;
}
.mega-dropdown h6 {
  margin: 15px 0;
  font-size: 15px;
}

/*************/
/* General Listing*/
/*************/
.general-listing {
  padding: 0px;
  margin: 0px;
}
.general-listing li {
  list-style: none;
}
.general-listing li a {
  color: #8d97ad;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 10px 0;
  -webkit-transition: 0.2s ease-in;
  -o-transition: 0.2s ease-in;
  transition: 0.2s ease-in;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.general-listing li:hover a {
  color: #316ce8;
  padding-left: 10px;
}
.general-listing li i {
  margin-right: 7px;
  vertical-align: middle;
}
.general-listing.two-part li {
  width: 49%;
  display: inline-block;
}
.general-listing.only-li li {
  padding: 5px 0;
}

/*************/
/* form dark*/
/*************/
.form-control-dark {
  background: rgba(120, 130, 140, 0.13);
  color: #ffffff;
  border-color: rgba(120, 130, 140, 0.13);
}
.form-control-dark:focus {
  background: rgba(120, 130, 140, 0.13);
  color: #ffffff;
}

/*************/
/* social-round */
/*************/
.round-social a {
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  display: inline-block;
  border-radius: 100%;
  margin: 0 5px;
  -webkit-transition: 0.2s ease-in;
  -o-transition: 0.2s ease-in;
  transition: 0.2s ease-in;
}
.round-social a:hover {
  -webkit-transform: translate3d(0px, -5px, 0px);
  transform: translate3d(0px, -5px, 0px);
}

.round-social.light a {
  color: #263238;
  background: #f4f8fa;
}

.round-social.light a:hover {
  background: #263238;
  color: #ffffff;
}

/*************/
/* Form Banner */
/*************/
.banner {
  position: relative;
  max-height: 700px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
  width: 100%;
  display: table;
}
.banner .banner-content {
  display: table-cell;
  vertical-align: middle;
  width: 100%;
}

@media (max-width: 992px) {
  .banner {
    height: auto !important;
    padding: 100px 0;
  }
}

@media (max-width: 767px) {
  .banner {
    padding: 30px 0;
  }
}

.form-control {
  padding: 0.75rem;
  border: 2px solid rgba(120, 130, 140, 0.13);
}

select.form-control:not([size]):not([multiple]) {
  height: calc(2.25rem + 12px);
}

/*******************
UI-Elements
******************/
/*******************
Buttons
******************/
.button-group .btn {
  margin-bottom: 7px;
}

/*******************
Grid page
******************/
.show-grid {
  margin-bottom: 10px;
  padding: 0 15px;
}

.show-grid [class^='col-'] {
  padding-top: 10px;
  padding-bottom: 10px;
  border: 1px solid rgba(120, 130, 140, 0.13);
  background-color: #f4f8fa;
}

/*******************
list and media
******************/
.list-group a.list-group-item:hover {
  background: #f4f8fa;
}

.list-group-item.active,
.list-group .list-group-item.active:hover {
  background: #316ce8;
  border-color: #316ce8;
}

.list-group-item.disabled {
  color: #8d97ad;
  background: #f4f8fa;
}

.media {
  border: 1px solid rgba(120, 130, 140, 0.13);
  margin-bottom: 10px;
  padding: 15px;
}

/*******************
Notification page Jquery toaster
******************/
.alert-rounded {
  border-radius: 60px;
}

/*******************
Progress bar
******************/
.progress.active .progress-bar,
.progress-bar.active {
  -webkit-animation: progress-bar-stripes 2s linear infinite;
  -o-animation: progress-bar-stripes 2s linear infinite;
  animation: progress-bar-stripes 2s linear infinite;
}

.progress-vertical {
  min-height: 250px;
  height: 250px;
  position: relative;
  display: inline-block;
  margin-bottom: 0;
  margin-right: 20px;
}

.progress-vertical-bottom {
  min-height: 250px;
  height: 250px;
  position: relative;
  display: inline-block;
  margin-bottom: 0;
  margin-right: 20px;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.progress-animated {
  -webkit-animation-duration: 5s;
  -webkit-animation-name: myanimation;
  -webkit-transition: 5s all;
  animation-duration: 5s;
  animation-name: myanimation;
  -o-transition: 5s all;
  transition: 5s all;
}

@-webkit-keyframes myanimation {
  from {
    width: 0;
  }
}

@keyframes myanimation {
  from {
    width: 0;
  }
}

/*Mystyle*/
.progress {
  height: 6px;
}

/*******************
Vertical tabs
******************/
.vtabs {
  display: table;
}
.vtabs .tabs-vertical {
  width: 150px;
  border-bottom: 0px;
  border-right: 1px solid rgba(120, 130, 140, 0.13);
  display: table-cell;
  vertical-align: top;
}
.vtabs .tabs-vertical li .nav-link {
  color: #263238;
  margin-bottom: 10px;
  border: 0px;
  border-radius: 4px 0 0 4px;
}
.vtabs .tab-content {
  display: table-cell;
  padding: 20px;
  vertical-align: top;
}

.tabs-vertical li .nav-link.active,
.tabs-vertical li .nav-link:hover,
.tabs-vertical li .nav-link.active:focus {
  background: #316ce8;
  border: 0px;
  color: #ffffff;
}

/*Custom vertical tab*/
.customvtab .tabs-vertical li .nav-link.active,
.customvtab .tabs-vertical li .nav-link:hover,
.customvtab .tabs-vertical li .nav-link:focus {
  background: #ffffff;
  border: 0px;
  border-right: 2px solid #316ce8;
  margin-right: -1px;
  color: #316ce8;
}

.tabcontent-border {
  border: 1px solid #ddd;
  border-top: 0px;
}

.customtab2 li a.nav-link {
  border: 0px;
  margin-right: 3px;
  color: #8d97ad;
}
.customtab2 li a.nav-link.active {
  background: #316ce8;
  color: #ffffff;
}
.customtab2 li a.nav-link:hover {
  color: #ffffff;
  background: #316ce8;
}

/*******************
Timeline page
******************/
.timeline {
  position: relative;
  padding: 20px 0 20px;
  list-style: none;
  max-width: 1200px;
  margin: 0 auto;
}

.timeline:before {
  content: ' ';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  width: 3px;
  margin-left: -1.5px;
  background-color: #f4f8fa;
}

.timeline > li {
  position: relative;
  margin-bottom: 20px;
}

.timeline > li:before,
.timeline > li:after {
  content: ' ';
  display: table;
}

.timeline > li:after {
  clear: both;
}

.timeline > li:before,
.timeline > li:after {
  content: ' ';
  display: table;
}

.timeline > li:after {
  clear: both;
}

.timeline > li > .timeline-panel {
  float: left;
  position: relative;
  width: 46%;
  padding: 20px;
  border: 1px solid rgba(120, 130, 140, 0.13);
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.05);
}

.timeline > li > .timeline-panel:before {
  content: ' ';
  display: inline-block;
  position: absolute;
  top: 26px;
  right: -8px;
  border-top: 8px solid transparent;
  border-right: 0 solid rgba(120, 130, 140, 0.13);
  border-bottom: 8px solid transparent;
  border-left: 8px solid rgba(120, 130, 140, 0.13);
}

.timeline > li > .timeline-panel:after {
  content: ' ';
  display: inline-block;
  position: absolute;
  top: 27px;
  right: -7px;
  border-top: 7px solid transparent;
  border-right: 0 solid #ffffff;
  border-bottom: 7px solid transparent;
  border-left: 7px solid #ffffff;
}

.timeline > li > .timeline-badge {
  z-index: 10;
  position: absolute;
  top: 16px;
  left: 50%;
  width: 50px;
  height: 50px;
  margin-left: -25px;
  border-radius: 50% 50% 50% 50%;
  text-align: center;
  font-size: 1.4em;
  line-height: 46px;
  color: #fff;
  overflow: hidden;
}

.timeline > li.timeline-inverted > .timeline-panel {
  float: right;
}

.timeline > li.timeline-inverted > .timeline-panel:before {
  right: auto;
  left: -8px;
  border-right-width: 8px;
  border-left-width: 0;
}

.timeline > li.timeline-inverted > .timeline-panel:after {
  right: auto;
  left: -7px;
  border-right-width: 7px;
  border-left-width: 0;
}

.timeline-badge.primary {
  background-color: #316ce8;
}

.timeline-badge.success {
  background-color: #2cdd9b;
}

.timeline-badge.warning {
  background-color: #fec500;
}

.timeline-badge.danger {
  background-color: #ff4d7e;
}

.timeline-badge.info {
  background-color: #188ef4;
}

.timeline-title {
  margin-top: 0;
  color: inherit;
  font-weight: 400;
}

.timeline-body > p,
.timeline-body > ul {
  margin-bottom: 0;
}

.timeline-body > p + p {
  margin-top: 5px;
}

/*==============================================================
 Ui-bootstrap
 ============================================================== */
ul.list-icons {
  margin: 0px;
  padding: 0px;
}
ul.list-icons li {
  list-style: none;
  line-height: 30px;
  margin: 5px 0;
  -webkit-transition: 0.2s ease-in;
  -o-transition: 0.2s ease-in;
  transition: 0.2s ease-in;
}
ul.list-icons li a {
  color: #8d97ad;
}
ul.list-icons li a:hover {
  color: #316ce8;
}
ul.list-icons li i {
  font-size: 13px;
  padding-right: 8px;
}

ul.list-inline li {
  display: inline-block;
}
ul.list-inline li:first-child {
  padding-left: 0px;
}
ul.list-inline li a {
  color: #8d97ad;
}
ul.list-inline li a:hover {
  color: #316ce8;
}

ul.two-part {
  margin: 0px;
}
ul.two-part li {
  width: 48.8%;
}

/*Accordion*/
html body .accordion .card {
  margin-bottom: 0px;
}

#accordion4 .card-header {
  border: 1px solid rgba(120, 130, 140, 0.13);
  background: transparent;
  margin-bottom: 10px;
}
#accordion4 .card-header a {
  color: #8d97ad;
}
#accordion4 .card-header:hover {
  background-color: #188ef4;
  border: 1px solid #188ef4;
}
#accordion4 .card-header:hover a {
  color: #ffffff;
}

#accordion4 .card.active .card-header {
  background-color: #188ef4;
  border: 1px solid #188ef4;
}
#accordion4 .card.active .card-header a {
  color: #ffffff;
}

/*=====================
Breadcrumb
=====================*/
.bc-colored .breadcrumb-item,
.bc-colored .breadcrumb-item a {
  color: #ffffff;
}
.bc-colored .breadcrumb-item.active,
.bc-colored .breadcrumb-item a.active {
  opacity: 0.7;
}

.bc-colored .breadcrumb-item + .breadcrumb-item::before {
  color: rgba(255, 255, 255, 0.4);
}

.breadcrumb {
  margin-bottom: 0px;
}

/*******************
 Card title
*******************/
.card-title {
  font-size: 18px;
}

.card-subtitle {
  color: #8d97ad;
  margin-bottom: 30px;
  font-size: 14px;
}

.card-inverse .card-bodyquote .blockquote-footer,
.card-inverse .card-link,
.card-inverse .card-subtitle,
.card-inverse .card-text {
  color: rgba(255, 255, 255, 0.65);
}

.card-default .card-header {
  background: #ffffff;
  border-bottom: 0px;
}

.card-success {
  background: #2cdd9b;
  border-color: #2cdd9b;
}

.card-danger {
  background: #ff4d7e;
  border-color: #ff4d7e;
}

.card-warning {
  background: #fec500;
  border-color: #fec500;
}

.card-info {
  background: #188ef4;
  border-color: #188ef4;
}

.card-primary {
  background: #316ce8;
  border-color: #316ce8;
}

.card-dark {
  background: #1b1d1f;
  border-color: #1b1d1f;
}

.card-megna {
  background: #1dc8cd;
  border-color: #1dc8cd;
}

/*==============================================================
 Cards page
 ============================================================== */
.card-columns .card {
  margin-bottom: 20px;
}

.collapsing {
  -webkit-transition: height 0.08s ease;
  -o-transition: height 0.08s ease;
  transition: height 0.08s ease;
}

.card-info {
  background: #188ef4;
  border-color: #188ef4;
}

.card-primary {
  background: #316ce8;
  border-color: #316ce8;
}

.card-outline-info {
  border-color: #188ef4;
}
.card-outline-info .card-header {
  background: #188ef4;
  border-color: #188ef4;
}

.card-outline-inverse {
  border-color: #1b1d1f;
}
.card-outline-inverse .card-header {
  background: #1b1d1f;
  border-color: #1b1d1f;
}

.card-outline-warning {
  border-color: #fec500;
}
.card-outline-warning .card-header {
  background: #fec500;
  border-color: #fec500;
}

.card-outline-success {
  border-color: #2cdd9b;
}
.card-outline-success .card-header {
  background: #2cdd9b;
  border-color: #2cdd9b;
}

.card-outline-danger {
  border-color: #ff4d7e;
}
.card-outline-danger .card-header {
  background: #ff4d7e;
  border-color: #ff4d7e;
}

.card-outline-primary {
  border-color: #316ce8;
}
.card-outline-primary .card-header {
  background: #316ce8;
  border-color: #316ce8;
}

/*******************
Custom-select
******************/
.custom-select {
  background: url(https://smartpay.wisecare.co.kr/assets/images/ui/custom-select.png)
    right 0.75rem center no-repeat;
  border: 2px solid rgba(120, 130, 140, 0.13);
  height: calc(2.25rem + 12px);
}

/*******************
textarea
******************/
textarea {
  resize: none;
}

.form-control-danger,
.form-control-success,
.form-control-warning {
  padding-right: 2.25rem;
  background-repeat: no-repeat;
  background-position: center right 0.5625rem;
  -webkit-background-size: 1.125rem 1.125rem;
  background-size: 1.125rem 1.125rem;
}

.has-success .col-form-label,
.has-success .custom-control,
.has-success .form-check-label,
.has-success .form-control-feedback,
.has-success .form-control-label {
  color: #2cdd9b;
}

.has-success .form-control-success {
  background-image: url(https://smartpay.wisecare.co.kr/assets/images/ui/success.svg);
}

.has-success .form-control {
  border-color: #2cdd9b;
}

.has-warning .col-form-label,
.has-warning .custom-control,
.has-warning .form-check-label,
.has-warning .form-control-feedback,
.has-warning .form-control-label {
  color: #fec500;
}

.has-warning .form-control-warning {
  background-image: url(https://smartpay.wisecare.co.kr/assets/images/ui/warning.svg);
}

.has-warning .form-control {
  border-color: #fec500;
}

.has-danger .col-form-label,
.has-danger .custom-control,
.has-danger .form-check-label,
.has-danger .form-control-feedback,
.has-danger .form-control-label {
  color: #ff4d7e;
}

.has-danger .form-control-danger {
  background-image: url(https://smartpay.wisecare.co.kr/assets/images/ui/danger.svg);
}

.has-danger .form-control {
  border-color: #ff4d7e;
}

.input-group-addon [type='radio']:not(:checked),
.input-group-addon [type='radio']:checked,
.input-group-addon [type='checkbox']:not(:checked),
.input-group-addon [type='checkbox']:checked {
  position: initial;
  opacity: 1;
}

.input-form .btn {
  padding: 14px 12px;
}

.form-control-sm {
  padding: 0.25rem 0.5rem;
}

/*******************
custom modals
******************/
.custom-modal .close-btn {
  position: absolute;
  right: 12px;
  top: 10px;
  color: #8d97ad;
  font-size: 25px;
  background: #ffffff;
  width: 20px;
  height: 20px;
  text-align: center;
  display: block;
  border-radius: 100%;
  line-height: 19px;
}

.custom-modal .modal-body {
  overflow: hidden;
}

.custom-modal .modal-bg-img {
  background-size: cover;
  background-position: center center;
  min-height: 300px;
}

.modal1 .modal-bg {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center top;
  padding: 40px;
}

.modal1 .input-group {
  margin-top: -45px;
}

.modal2 .modal-bg {
  padding: 30px 30px 30px 15px;
}

.modal3 .modal-bg {
  padding: 50px 40px 60px 25px;
}

.modal4 .modal-bg {
  padding: 55px 55px 55px 40px;
}

/*******************
Overlay headers and thrid dropdown
******************/
.header-overlay {
  position: absolute;
  width: 100%;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: #316ce8;
}

.dropdown-submenu {
  position: relative;
}
.dropdown-submenu .dropdown-item > .ml-auto {
  position: absolute;
  right: 15px;
  top: 10px;
}

.dropdown-submenu > .dropdown-menu {
  top: 0;
  left: 100%;
  margin-left: 0;
  border-radius: 0.25rem;
  display: none;
}
.dropdown-submenu > .dropdown-menu.menu-right {
  left: auto;
  right: 100%;
}

.dropdown-submenu.pull-left {
  float: none;
}

.dropdown-submenu.pull-left > .dropdown-menu {
  left: -75%;
}

.dropdown-menu .divider {
  background-color: rgba(120, 130, 140, 0.13);
  height: 1px;
  margin: 9px 0;
  overflow: hidden;
}

/*******************
common-innerpage-banner
*******************/
.banner-innerpage {
  padding: 150px 0 100px;
  background-size: cover;
  background-position: center center;
}
.banner-innerpage .title {
  color: #ffffff;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 40px;
  line-height: 40px;
}
.banner-innerpage .subtitle {
  color: #ffffff;
}

@media (max-width: 900px) {
  .static-slider10 .title {
    font-size: 40px;
    line-height: 45px;
  }
}

.with-noborder .media {
  border: 0px;
  padding: 0px;
  margin: 50px 0;
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background: #316ce8;
}

/*******************
Footer 4
*******************/
.footer4 .f4-bottom-bar {
  padding-top: 10px;
  margin-top: 20px;
}
.footer4 .f4-bottom-bar a {
  color: #8d97ad;
}
.footer4 .f4-bottom-bar a:hover {
  color: #188ef4;
}

.footer4 .round-social.light a {
  width: 34px;
  height: 34px;
  line-height: 34px;
}
.footer4 .round-social.light a:hover {
  background: #188ef4;
}

/*******************
Modal in center
*******************/
.modal-dialog {
  min-height: calc(100vh - 60px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  overflow: auto;
}
@media (max-width: 768px) {
  .modal-dialog {
    min-height: calc(100vh - 20px);
  }
}

.animated {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
}

/*******************
Icons
*******************/
.icon-list-demo div {
  cursor: pointer;
  line-height: 60px;
  white-space: nowrap;
}
.icon-list-demo div p {
  margin: 10px 0;
  padding: 5px 0;
}
.icon-list-demo div:hover {
  color: #263238;
}
.icon-list-demo div:hover i {
  font-size: 2em;
}

.icon-list-demo i {
  -webkit-transition: all 0.2s;
  -webkit-transition: font-size 0.2s;
  display: inline-block;
  font-size: 18px;
  margin: 0 15px 0 10px;
  text-align: left;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  -o-transition: font-size 0.2s;
  transition: font-size 0.2s;
  vertical-align: middle;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.icon-list-demo .col-md-4,
.icon-list-demo .col-3 {
  border-radius: 4px;
}

/*
Template Name: Wrapkit Ui Kit
Author: Themedesigner
Email: niravjoshi87@gmail.com
File: scss
*/
/*==============================================================
 For Laptop & above all (1650px)
 ============================================================== */
/*==============================================================
 For Laptop & above all (1370px)
 ============================================================== */
/*-- ==============================================================
 Small Desktop & above all (1024px)
 ============================================================== */
@media (min-width: 1024px) {
  .contact4 .contact-box {
    padding: 80px 105px 80px 0px;
  }
  .hover-dropdown .navbar-nav > .dropdown:hover > .dropdown-menu {
    display: block;
    margin-top: 0px;
  }
  .navbar-nav > .dropdown .dropdown-menu {
    /* min-width: 210px;
    margin-top: 0px;  */
  }
  .dropdown-submenu:hover > .dropdown-menu {
    display: block;
  }
}

@media (max-width: 1023px) {
  /* .d-flex {
    display: block !important; }
    .d-flex.no-block {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important; } */
  .wrap-feature30-box {
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 50%;
  }
  .wrap-feature31-box .right-image,
  .contact4 .right-image,
  .form6 .right-image {
    position: relative;
    bottom: -95px;
  }
  .wrap-feature31-box .right-image img,
  .contact4 .right-image img,
  .form6 .right-image img {
    width: 100%;
  }
  .contact1 .detail-box {
    margin-top: 40px;
  }
  .contact1 .p-r-40 {
    padding-right: 0;
  }
  .contact4 .contact-box {
    padding: 40px 0px 40px 0px;
  }
  .contact3 .c-detail {
    text-align: center;
  }
  .contact3 .c-detail .m-r-20 {
    margin-right: 0;
  }
  .contact3 .c-detail h6 {
    margin-top: 20px;
  }
  .contact3 .m-l-30 {
    margin-left: 0;
  }
  .contact3 .contact-box {
    margin-top: 30px;
  }
  .wrap-feature34-box .left-image {
    position: relative;
    top: -90px;
  }
  .po-absolute {
    position: relative;
  }
  .contact2 .bg-image {
    margin-right: 15px;
    margin-left: 15px;
  }
  .topbar {
    background: #316ce8;
    position: relative;
  }
  .topbar.animated {
    -webkit-animation-duration: 0s;
    animation-duration: 0s;
  }
  .topbar.animated.slideInDown {
    -webkit-animation-name: none;
    animation-name: none;
  }
}

/*-- ==============================================================
 Ipad & above all(768px)
 ============================================================== */
/*-- ==============================================================
 Phone and below ipad(767px)
 ============================================================== */
@media (max-width: 767px) {
  /*This is for the Global*/
  .b-l,
  .b-r {
    border-left: 0px;
    border-right: 0px;
    border-bottom: 1px solid rgba(120, 130, 140, 0.13);
  }
  .img-inline li.half-width {
    width: 100%;
  }
  .both-space {
    margin: 10px 0;
  }
  .side-content {
    padding: 40px 20px;
  }
  .general-listing.two-part li {
    width: 100%;
    display: block;
  }
  /*This is for the feature 12*/
  .wrap-feature-12 .uneven-box {
    margin-top: 0px;
  }
  /*This is for the feature 16*/
  .wrap-feature-16 .with-text {
    margin-left: 0px;
  }
  /*This is for the feature 18*/
  .wrap-feature-18 .icon-position .icon-round {
    top: 157px;
    right: 30px;
  }
  /*This is for the feature 22*/
  .wrap-feature-22 .text-box,
  .wrap-feature23-box .text-box {
    padding: 20px 0px;
  }
  .wrap-feature30-box {
    position: relative;
  }
  .contact3 .p-l-0 {
    padding-left: 20px;
  }
  .contact4 .contact-box {
    padding-left: 15px;
    padding-right: 15px;
  }
  .modal2 .modal-bg {
    padding: 30px;
  }
  .modal3 .modal-bg {
    padding: 50px 40px 60px;
  }
  .modal4 .modal-bg {
    padding: 40px;
  }
  .dropdown-submenu > .dropdown-menu.show {
    display: block;
  }
}
